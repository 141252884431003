var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$root.currentpage != 'chat' || _vm.chatuser != null)?_c('v-app-bar',{staticClass:"px-3",style:(_vm.$root.lang == 'ar'
      ? 'right:' + _vm.$root.admin.extramargin
      : 'left:' + _vm.$root.admin.extramargin),attrs:{"color":"white","flat":"","app":"","height":"60px"},scopedSlots:_vm._u([(
      _vm.$user.getCurrentUser() &&
        _vm.$user.getCurrentUser().role.id === 1 &&
        _vm.$root.currentpage === 'dashboard'
    )?{key:"extension",fn:function(){return [_c('v-tabs',_vm._l((_vm.statsmenu),function(item){return _c('v-tab',{key:item.id,on:{"click":function($event){return _vm.globalClick(item.value)}}},[_c('v-avatar',[_c('unicon',{attrs:{"name":item.icon,"fill":'#0971CE'}})],1),_vm._v(_vm._s(item.text))],1)}),1)]},proxy:true}:(
      _vm.$user.getCurrentUser() &&
        _vm.$user.getCurrentUser().role.id === 1 &&
        _vm.$root.currentpage === 'manage'
    )?{key:"extension",fn:function(){return [_c('v-tabs',_vm._l((_vm.managemenu),function(item){return _c('v-tab',{key:item.id,on:{"click":function($event){return _vm.globalClick(item.value)}}},[_c('v-avatar',[_c('unicon',{attrs:{"name":item.icon,"fill":'#0971CE'}})],1),_vm._v(_vm._s(item.text))],1)}),1)]},proxy:true}:null],null,true)},[(_vm.$root.currentpage != 'chat')?[_c('div',{staticClass:"font-size-h4 text-primary mx-1 mb-0"},[_vm._v(" "+_vm._s(this.$root.title)+" ")]),_c('v-spacer')]:[(_vm.chatuser)?_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"#f2f7fd","size":"40"}},[(_vm.chatuser.remote != null && _vm.chatuser.remote.avatar)?_c('v-avatar',{attrs:{"color":"#f2f7fd","size":"40"}},[_c('v-img',{attrs:{"src":_vm.$root.apiURL + 'assets/' + _vm.chatuser.remote.avatar.private_hash}})],1):_c('v-avatar',{attrs:{"color":"#f2f7fd","size":"40"}},[_c('div',{staticClass:"primary--text font-size-h1",staticStyle:{"text-transform":"uppercase","width":"100%"}},[(_vm.chatuser.remote != null && _vm.chatuser.remote.first_name)?_c('span',[_vm._v(_vm._s(_vm.chatuser.remote.first_name.charAt(0)))]):_c('span',[_vm._v("D")])])])],1),_c('v-list-item-content',[_c('v-list-item-title',[(_vm.chatuser.remote)?_c('span',[_vm._v(_vm._s(_vm.chatuser.remote.first_name)+" "+_vm._s(_vm.chatuser.remote.last_name))]):_c('span',[_vm._v(_vm._s(_vm.chatuser.title))])]),(_vm.chatuser.remote.online)?_c('v-list-item-subtitle',[_vm._v("Online")]):_vm._e()],1)],1):_vm._e()]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }