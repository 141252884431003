<template>
  <v-app-bar
    v-if="$root.currentpage != 'chat' || chatuser != null"
    :style="
      $root.lang == 'ar'
        ? 'right:' + $root.admin.extramargin
        : 'left:' + $root.admin.extramargin
    "
    color="white"
    flat
    app
    height="60px"
    class="px-3"
  >
    <template v-if="$root.currentpage != 'chat'">
      <div class="font-size-h4 text-primary mx-1 mb-0">
        {{ this.$root.title }}
      </div>
      <v-spacer />
    </template>
    <template v-else>
      <v-list-item v-if="chatuser">
        <v-list-item-avatar color="#f2f7fd" size="40">
          <v-avatar
            v-if="chatuser.remote != null && chatuser.remote.avatar"
            color="#f2f7fd"
            size="40"
          >
            <v-img
              :src="
                $root.apiURL + 'assets/' + chatuser.remote.avatar.private_hash
              "
            ></v-img>
          </v-avatar>
          <v-avatar v-else color="#f2f7fd" size="40">
            <div
              style="text-transform: uppercase; width: 100%"
              class="primary--text font-size-h1"
            >
              <span
                v-if="chatuser.remote != null && chatuser.remote.first_name"
                >{{ chatuser.remote.first_name.charAt(0) }}</span
              >
              <span v-else>D</span>
            </div>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <span v-if="chatuser.remote"
              >{{ chatuser.remote.first_name }}
              {{ chatuser.remote.last_name }}</span
            >
            <span v-else>{{ chatuser.title }}</span></v-list-item-title
          >
          <v-list-item-subtitle v-if="chatuser.remote.online"
            >Online</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </template>
    <template
      v-if="
        $user.getCurrentUser() &&
          $user.getCurrentUser().role.id === 1 &&
          $root.currentpage === 'dashboard'
      "
      v-slot:extension
    >
      <v-tabs>
        <v-tab
          v-for="item in statsmenu"
          :key="item.id"
          @click="globalClick(item.value)"
          ><v-avatar>
            <unicon :name="item.icon" :fill="'#0971CE'"></unicon> </v-avatar
          >{{ item.text }}</v-tab
        >
      </v-tabs>
    </template>
    <template
      v-else-if="
        $user.getCurrentUser() &&
          $user.getCurrentUser().role.id === 1 &&
          $root.currentpage === 'manage'
      "
      v-slot:extension
    >
      <v-tabs>
        <v-tab
          v-for="item in managemenu"
          :key="item.id"
          @click="globalClick(item.value)"
          ><v-avatar>
            <unicon :name="item.icon" :fill="'#0971CE'"></unicon> </v-avatar
          >{{ item.text }}</v-tab
        >
      </v-tabs>
    </template>
  </v-app-bar>
</template>
<script>
export default {
  name: "AppTop",
  data() {
    return {
      //---- chat user
      chatuser: null,

      //menu
      managemenu: [
        { id: 1, text: "Appointments", value: "manage-apps", icon: "calender" },
        {
          id: 2,
          text: "Questions",
          value: "manage-questions",
          icon: "comment-alt-question"
        },
        {
          id: 3,
          text: "Second Opnion",
          value: "manage-referral",
          icon: "comment-redo"
        },
        { id: 4, text: "Feed", value: "manage-feed", icon: "list-ul" }
      ],
      statsmenu: [
        { id: 1, text: "Users", value: "stats-users", icon: "user-circle" },
        { id: 2, text: "Appointments", value: "stats-apps", icon: "calender" },
        {
          id: 3,
          text: "Questions",
          value: "stats-questions",
          icon: "comment-alt-question"
        }
      ]
    };
  },
  components: {
    //KTQuickSearch,
  },
  methods: {
    globalClick(to) {
      this.$util.EventBus.$emit("GlobalClick", to);
    }
  },
  computed: {},
  mounted() {
    this.$util.EventBus.$on("chatRoomChange", () => {
      this.chatuser = this.$chat.getActiveRoom();
    });

    this.$util.EventBus.$on("onlineStatusUpdate", data => {
      if (this.chatuser != null) {
        if (this.chatuser.remote.external_id == data.external_id) {
          this.chatuser.remote.online = data.status;
          this.$forceUpdate();
        }
      }
    });
  }
};
</script>

<style scoped></style>
